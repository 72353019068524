import React, { forwardRef, useState } from 'react';
import { InputProps } from '@business/interfaces/input';
import { SVGAssets } from '@business/assets/vectors';

const getPasswordDecorator = (
  inputType: string,
  callback: (type: string) => void
) => {
  const iconSizeClass = 'w-8 h-5 cursor-pointer';
  if (inputType === 'text') {
    return (
      <SVGAssets.EyeIcon
        className={iconSizeClass}
        data-testid="visibility-on-icon"
        onClick={() => callback('password')}
      />
    );
  } else {
    return (
      <SVGAssets.VisibilityOff
        className={iconSizeClass}
        data-testid="visibility-off-icon"
        onClick={() => callback('text')}
      />
    );
  }
};

export const InputField: React.ForwardRefExoticComponent<
  Omit<InputProps, 'ref'> & React.RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      inputWrapperClassName,
      label,
      labelWrapperClassName,
      labelClassName,
      color,
      error,
      startDecorator,
      endDecorator,
      ...props
    },
    ref
  ) => {
    const actualType = props?.type;
    const [inputType, setInputType] = useState<string>(props?.type);
    function handleEndDecoratorVisibility(type: string) {
      setInputType(type);
    }

    return (
      <>
        <div
          className={`${
            actualType === 'password'
              ? 'password-visibility relative flex items-center'
              : ''
          } ${inputWrapperClassName} ${error ? 'error' : color}`}>
          {startDecorator}
          <div
            className={`${
              label ? 'relative z-0 flex-col w-full' : ''
            } ${labelWrapperClassName} w-full`}>
            <input
              autoComplete="off"
              {...props}
              ref={ref}
              id={props.name}
              type={inputType}
              data-testid={`input-field-${props.name}`}
            />
            {label && (
              <label
                htmlFor={props.name}
                data-testid="input-label"
                className={labelClassName}>
                {label}
              </label>
            )}
          </div>
          <div className="absolute right-3 mt-3 top-0 text-sm mr-3">
            {actualType === 'password' && typeof endDecorator === 'undefined'
              ? getPasswordDecorator(inputType, handleEndDecoratorVisibility)
              : endDecorator}
          </div>
        </div>
        {error && <div className="error-msg text-sm pt-1">{error}</div>}
      </>
    );
  }
);

InputField.displayName = 'InputField';
InputField.defaultProps = {
  type: 'text',
  placeholder: ' ',
  color: 'success',
  inputWrapperClassName: 'w-full input-wrapper',
  labelWrapperClassName: '',
  className:
    'px-4 block py-2.5 w-full font-semibold bg-transparent appearance-none 0 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
  // labelClassName:
  //   'pl-2 absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
};

import React from 'react';
import { SVGAssets } from '@business/assets/vectors';
export const HeaderWithoutNav: React.FC = () => {
  return (
    <div className="navbar volunteer-head app-header-logo-only bg-white shadow-normal w-full py-2 lg:py-4 px-5 md:px-12">
      <div className="flex-1 cursor-pointer">
        <SVGAssets.Logo
          className="w-12 h-12"
          onClick={() => console.log('')}
          data-testid="app-header-logo-only"
        />
      </div>
    </div>
  );
};

export default HeaderWithoutNav;

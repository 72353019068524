const CryptoJS = require('crypto-js');

export function decode(data: any) {
  const parsedKey = CryptoJS.enc.Hex.parse(data?.key);
  const parsedIv = CryptoJS.enc.Hex.parse(data?.iv);
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(data?.token) },
    parsedKey,
    { iv: parsedIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedText);
}

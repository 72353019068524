import { setToken } from '@ports/common/sessionStateSlice';
import { persister } from '@ports/common/store';
import { routePaths } from '@utils/constants/routePaths';
import { signOut } from 'aws-amplify/auth';
import { apiSlices } from '../getState';

export const clearCache = async (dispatch: any) => {
  await apiSlices.forEach((api: any) => {
    dispatch(api?.util?.resetApiState());
  });
};

export const signOutHandler = async (dispatch: any, navigate: any) => {
  await signOut();
  dispatch(setToken({ token: null }));
  sessionStorage.clear();
  clearCache(dispatch);
  await persister?.pause();
  persister?.flush().then(async () => {
    await persister?.purge();
    return;
  });
  navigate(routePaths.SignIn);
};

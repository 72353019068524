import { FC, forwardRef } from 'react';
import { CheckboxProps } from '@business/interfaces/checkbox';

export const Checkbox: FC<CheckboxProps> = forwardRef<
  HTMLInputElement,
  CheckboxProps
>(
  (
    { wrapperClassName, labelClassName, label, error, value, id, ...props },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapperClassName ?? ''}`}
          data-testid={'checkbox-field'}>
          <input
            {...props}
            value={value}
            ref={ref}
            type="checkbox"
            id={id ?? props.name + (label ? (value as string) : '')}
            data-testid={`checkbox-field-${props.name}`}
          />
          {label && (
            <label
              htmlFor={id ?? props.name + (value as string)}
              data-testid="checkbox-label"
              className={`${
                labelClassName ?? ''
              } cursor-pointer whitespace-nowrap`}>
              {label}
            </label>
          )}
          <br />
        </div>
        {error && <div className="error-msg">{error}</div>}
      </>
    );
  }
);
Checkbox.displayName = 'Checkbox';

import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type RoleFilterProps = {
  role: string;
  label: string;
};

const initialState: RoleFilterProps = {
  role: '',
  label: ''
};

export const roleFilterStateSlice = createSlice({
  name: 'roleFilterStateSlice',
  initialState,
  reducers: {
    setFilterRole: (state, action) => {
      state.role = action.payload;
    },
    setFilterLabel: (state, action) => {
      state.label = action.payload;
    },
    resetFilterRole: () => {
      return initialState;
    }
  }
});

export const { setFilterRole, setFilterLabel, resetFilterRole } =
  roleFilterStateSlice.actions;
export const getFilterRole = (state: RootState) =>
  state.roleFilterStateSlice.role;
export const getFilterLabel = (state: RootState) =>
  state.roleFilterStateSlice.label;

import { FC } from 'react';

interface BaseAppLayoutProps {
  id?: string;
}

const BaseAppLayout: FC<React.PropsWithChildren<BaseAppLayoutProps>> = ({
  children
}) => {
  return <>{children}</>;
};
export default BaseAppLayout;

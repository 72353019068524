import { FC } from 'react';
// import { AppLoader } from '@business/workflows/ComponentUI/AppLoader';
import { HeaderWithoutNav } from '@business/workflows/ComponentUI/Header/HeaderWithoutNav';
import { Footer } from '@business/workflows/ComponentUI';

interface AppErrorLayoutProps {
  id?: string;
  mainClass?: string;
  // baseAppClass?: string;
}

const AppErrorLayout: FC<React.PropsWithChildren<AppErrorLayoutProps>> = ({
  id = '',
  mainClass = '',
  // baseAppClass = '',
  children
}) => {
  return (
    <>
      <main
        className="lasagna-theme relative w-full app-error-layout"
        data-testid="app-error-layout">
        <div data-testid={id}>
          <div className={mainClass}>
            <div data-testid="header-component">
              <HeaderWithoutNav />
            </div>
            <section className="th-container content-section w-full">
              {children}
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default AppErrorLayout;

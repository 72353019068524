import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type ScheduleProps = {
  isMySchedule: boolean;
};

const initialState: ScheduleProps = {
  isMySchedule: false
};

export const scheduleStateSlice = createSlice({
  name: 'scheduleStateSlice',
  initialState,
  reducers: {
    setMySchedule: (state, action) => {
      state.isMySchedule = action.payload;
    },
    resetMySchedule: () => {
      return initialState;
    }
  }
});

export const { setMySchedule, resetMySchedule } = scheduleStateSlice.actions;
export const getMySchedule = (state: RootState) =>
  state.scheduleStateSlice.isMySchedule;

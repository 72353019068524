import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type sideBarStateType = {
  userSideBar: any[];
  itemClicked: boolean;
  topMenuClicked: string;
  resource: {
    type: string;
    url: string;
    title: string;
    index?: number;
    uuid: string;
    resource: string;
    origin?: string;
  };
  resourceType?: string;
  permission?: string;
};

const initialState: sideBarStateType = {
  userSideBar: [],
  itemClicked: false,
  topMenuClicked: '',
  resource: {
    type: '',
    url: '',
    title: '',
    index: 0,
    uuid: '',
    resource: '',
    origin: ''
  },
  resourceType: 'intro',
  permission: ''
};

export const sideBarStateSlice = createSlice({
  name: 'sideBarStateSlice',
  initialState,
  reducers: {
    setMenuItems: (state, action) => {
      state.userSideBar = action.payload;
    },
    setItemClicked: (state, action) => {
      state.itemClicked = action.payload;
    },
    setTopMenuClicked: (state, action) => {
      state.topMenuClicked = action.payload;
    },
    setResource: (state, action) => {
      state.resource = action.payload;
    },
    setResourceType: (state, action) => {
      state.resourceType = action.payload;
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
    resetPermission: () => {
      return initialState;
    }
  }
});

export const {
  setMenuItems,
  setItemClicked,
  setTopMenuClicked,
  setResource,
  setResourceType,
  setPermission,
  resetPermission
} = sideBarStateSlice.actions;
export const getMenuItems = (state: RootState) =>
  state.sideBarStateSlice.userSideBar;
export const getItemClicked = (state: RootState) =>
  state.sideBarStateSlice.itemClicked;
export const getTopMenuClicked = (state: RootState) =>
  state.sideBarStateSlice.topMenuClicked;
export const getResource = (state: RootState) =>
  state.sideBarStateSlice.resource;
export const getResourceType = (state: RootState) =>
  state.sideBarStateSlice.resourceType;
export const getPermission = (state: RootState) =>
  state.sideBarStateSlice.permission;

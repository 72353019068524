import { FC } from 'react';
import { Button } from '../Button';
import { NoDataComponent } from '../NoData';
import { Table } from './Table';

interface TableGroupByProps {
  selectedGroupMatches?: string;
  selectedGroupMatchesValue?: string;
  columns?: any[];
  filteredColumns?: any[];
  resultsData?: any;
  reducerTableColumns?: any;
  setSelectedGroupMatchesValue?: (value: any) => void;
  className?: string;
  classNameHead?: string;
  classNameBody?: string;
  tableBodyClassName?: string;
  classNameTd?: string;
  classNameMain?: string;
  alternateRowColor?: boolean;
  rowClickHandler?: (value: any) => void;
}

export const TableGroupBy: FC<TableGroupByProps> = ({
  selectedGroupMatches,
  selectedGroupMatchesValue,
  columns,
  filteredColumns,
  resultsData,
  reducerTableColumns,
  setSelectedGroupMatchesValue,
  className,
  classNameHead,
  classNameBody,
  tableBodyClassName,
  classNameTd,
  classNameMain,
  alternateRowColor,
  rowClickHandler
}) => {
  return (
    <>
      {selectedGroupMatches === '' ? (
        <Table
          tableColumns={columns ?? []}
          tableData={resultsData?.results ?? []}
          className={className}
          classNameHead={classNameHead}
          classNameBody={classNameBody}
          tableBodyClassName={tableBodyClassName}
          classNameTd={classNameTd}
          classNameMain={classNameMain}
          alternateRowColor={alternateRowColor}
          rowClickHandler={rowClickHandler}
        />
      ) : resultsData?.results?.length > 0 ? (
        Object.keys(reducerTableColumns).map((row: any) => {
          return (
            <div className="block" key={row}>
              <Button
                btnStyle="outline-filled"
                className="my-1 rounded-lg w-full flex justify-between p-2"
                onClick={() => {
                  if (selectedGroupMatchesValue === row) {
                    setSelectedGroupMatchesValue('');
                  } else {
                    setSelectedGroupMatchesValue(row);
                  }
                }}>
                <p>{row !== 'null' ? row : '--'}</p>
                <p>
                  {'('}
                  {reducerTableColumns[row]?.length}
                  {')'}
                </p>
              </Button>
              {selectedGroupMatchesValue === row && (
                <Table
                  tableColumns={filteredColumns ?? []}
                  tableData={
                    reducerTableColumns[selectedGroupMatchesValue] ?? []
                  }
                  className={className}
                  classNameHead={classNameHead}
                  classNameBody={classNameBody}
                  tableBodyClassName={tableBodyClassName}
                  classNameTd={classNameTd}
                  classNameMain={classNameMain}
                  alternateRowColor={alternateRowColor}
                  rowClickHandler={rowClickHandler}
                />
              )}
            </div>
          );
        })
      ) : (
        <NoDataComponent />
      )}
    </>
  );
};

import loginBackground from './login-background.webp';
import MapMarker from './map-marker.webp';
import ProfileImage from './profile-image.webp';
import CoverImage from './cover-image.webp';
import FrameBackground from './frame-bg.webp';
import MyArea from './my-area.webp';
import Loader from './loader.gif';
import SetStates from './set-states.webp';
import NoData from './no-data.webp';
import Page404Image from './404.png';
import RequestMeal from './request-meal.webp';
import Meal from './meal.webp';
import MealRequest from './meal-request.webp';
import Family from './Family.png';
import Volunteer from './Chef.png';
import Matched from './Matched-map.png';
import Unmatched from './new-unmacthed-red.png';

export const Images = {
  loginBackground,
  MapMarker,
  ProfileImage,
  CoverImage,
  FrameBackground,
  MyArea,
  Loader,
  SetStates,
  NoData,
  Page404Image,
  RequestMeal,
  Meal,
  MealRequest,
  Family,
  Volunteer,
  Matched,
  Unmatched
};

import {
  Alert,
  Button,
  InputField,
  Select
} from '@business/workflows/ComponentUI';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRegionCountryListQuery } from '@ports/internal-services/country/countrySlice';
import { useForm } from 'react-hook-form';
import { StateFormValues } from '@business/interfaces/state';
import {
  useRegionStateCreateMutation,
  useRegionStateReadQuery,
  useRegionStateUpdateMutation
} from '@ports/internal-services/state/stateSlice';
import { setAlertDetails } from '@ports/common/alertStateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AlertProps } from '@business/interfaces/alert/AlertProps';
import { useRegionZipcodesLiteQuery } from '@ports/internal-services/zipCodes/zipCodeSlice';
import { getStateId, setStateId } from '@ports/common/stateDetailsSlice';
import { routePaths } from '@utils/constants/routePaths';
import { useNavigate } from 'react-router-dom';
import { useUserUsersLiteQuery } from '@ports/internal-services/user/userSlice';
import { LocalLeaderCard } from '../AreaComponent/LocalLeaderCard';
import { AssignedZipCodeCard } from '../AreaComponent/AssignedZipCodeCard';
import { AreaCard } from '../AreaComponent/AreaCard';
import BaseAppLayout from '../Layout/BaseAppLayout';

const CreateState = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<StateFormValues>();

  const dispatch = useDispatch();
  const stateId = useSelector(getStateId);
  const navigate = useNavigate();
  const [zipPage, setZipPage] = useState(10);
  const [zipFilterOptions, setZipFilterOptions] = useState<{
    search: string;
    limit: number;
    stateId: string;
  }>({
    search: '',
    limit: zipPage,
    stateId: stateId?.id
  });

  const [selectedZipCodes, setSelectedZipCodes] = useState<any[]>([]);
  const [selectedLocalLeaders, setSelectedLocalLeaders] = useState<any[]>([]);
  const [page, setPage] = useState(8);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<AlertProps | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [userDataFilterOption, setUserDataFilterOption] = useState<{
    search: string;
    limit: number;
    offset: number;
    stateId: string;
    groupsName: string;
  }>({
    search: '',
    limit: page,
    offset: 0,
    stateId: stateId?.id,
    groupsName: 'Regional Director'
  });
  const [zipCodeSearchQuery, setZipCodeSearchQuery] = useState<string>('');
  const [isChange, setIsChange] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const cardRef = useRef(null);
  const zipCardRef = useRef(null);

  const { data: countryListData, refetch: countryRefetch } =
    useRegionCountryListQuery({
      search: '',
      limit: 1000,
      offset: 0
    });

  const { data: stateData, refetch: stateRefetch } = useRegionStateReadQuery({
    uuid: stateId?.id
  });

  const {
    data: zipCodeList,
    isFetching: zipCodeIsFetching,
    refetch: zipCodeRefetch
  } = useRegionZipcodesLiteQuery({ ...zipFilterOptions });

  const {
    data: usersData,
    refetch: refetchUsersData,
    isFetching: userFetch
  } = useUserUsersLiteQuery({
    ...userDataFilterOption
  });

  const [updateState] = useRegionStateUpdateMutation();
  const [createState] = useRegionStateCreateMutation();

  useEffect(() => {
    const handler = setTimeout(() => {
      setZipFilterOptions({ ...zipFilterOptions, search: zipCodeSearchQuery });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [zipCodeSearchQuery]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setUserDataFilterOption({
        ...userDataFilterOption,
        search: searchQuery
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    zipCodeRefetch();
  }, [zipFilterOptions]);

  useEffect(() => {
    zipCodeRefetch();
    refetchUsersData();
    countryRefetch();
  }, []);

  useEffect(() => {
    stateRefetch();
    zipCodeRefetch();
  }, [stateId]);

  useEffect(() => {
    if (stateData) {
      setSelectedZipCodes(
        stateData?.results?.zipcodes?.map((item: any) => item) ?? []
      );
      setSelectedLocalLeaders(
        stateData?.results?.directors?.map(item => item) ?? []
      );
      reset({
        name: stateData?.results?.name,
        code: stateData?.results?.st,
        country: stateData?.results?.country
      });
    }
  }, [stateData]);

  const countryOptions = useMemo(() => {
    return countryListData?.results
      ? countryListData?.results?.map((item: any) => ({
          value: item?.uuid,
          label: item?.name
        }))
      : [];
  }, [countryListData?.results]);

  const hasZipMore = zipCodeList?.results?.length < zipCodeList?.count || false;

  const hasMore = usersData?.results?.length < usersData?.count || false;

  const handleZipCodeSelection = (zipCode: any, isSelected: boolean) => {
    setSelectedZipCodes(prevSelected => {
      const updatedSelected = isSelected
        ? [...prevSelected, zipCode]
        : prevSelected.filter(code => code?.zip !== zipCode?.zip);

      const prevZipCodes = stateData?.results?.zipcodes?.map(
        (item: any) => item
      );
      const hasChange = checkerfunction(updatedSelected, prevZipCodes);
      setIsChange(hasChange);
      return updatedSelected;
    });
  };

  const checkerfunction = (selected: any, prev: any) => {
    const Change =
      selected?.length !== prev?.length ||
      selected?.some(
        (leader: any) =>
          !prev?.some((prevLeader: any) => prevLeader?.uuid === leader?.uuid)
      );

    return Change;
  };

  const handleRemoveZipCode = (zipCode: any) => {
    setSelectedZipCodes(prevSelected => {
      const updatedZipCodes = prevSelected.filter(
        code => code?.zip !== zipCode?.zip
      );
      const prevZipCodes = stateData?.results?.zipcodes?.map(
        (item: any) => item
      );
      const hasChange = checkerfunction(updatedZipCodes, prevZipCodes);
      setIsChange(hasChange);
      return updatedZipCodes;
    });
  };

  const handleLocalLeaderSelection = (leader: any, isSelected: boolean) => {
    setSelectedLocalLeaders(prevSelected => {
      const updatedLeaders = isSelected
        ? [...prevSelected, leader]
        : prevSelected.filter(selected => selected?.uuid !== leader?.uuid);

      const prevLeaders = stateData?.results?.directors?.map(
        (item: any) => item
      );
      const hasChange = checkerfunction(updatedLeaders, prevLeaders);
      setIsChange(hasChange);
      return updatedLeaders;
    });
  };

  const handleRemoveLocalLeader = (leader: any) => {
    setSelectedLocalLeaders(prevSelected => {
      const updatedLeaders = prevSelected.filter(
        selected => selected?.uuid !== leader?.uuid
      );
      const prevLeaders = stateData?.results?.directors?.map(
        (item: any) => item
      );
      const hasChange = checkerfunction(updatedLeaders, prevLeaders);
      setIsChange(hasChange);
      return updatedLeaders;
    });
  };

  const triggerPopupData = (
    local: boolean,
    success: boolean,
    heading: string,
    scope: string,
    args: string
  ) => {
    const headingData = args
      ? t(heading, { args: t(`messageCode.${args}`) })
      : t(heading);
    const statusData = success ? 'success' : 'error';
    const data: any = {
      type: statusData,
      heading: headingData
    };
    if (local) {
      setShowAlert(true);
      setAlertData(data);
    } else {
      dispatch(
        setAlertDetails({
          alert: data,
          scope: 'createState'
        })
      );
    }
  };
  const responseHandler = (res: any) => {
    if (res?.data?.ok) {
      triggerPopupData(
        false,
        true,
        `messageCode.${res?.data?.message?.code}`,
        'updateState',
        res?.data?.message?.args
      );
      navigate(routePaths.StateList);
    } else {
      triggerPopupData(
        true,
        false,
        `messageCode.${res?.error?.data?.message?.code}`,
        '',
        res?.error?.data?.message?.args
      );
    }
  };
  const onSubmit = (data: StateFormValues) => {
    if (selectedLocalLeaders.length === 0) {
      triggerPopupData(true, false, 'state.localLeaderSelect', '', '');
      return;
    } else if (selectedZipCodes.length === 0) {
      triggerPopupData(true, false, 'state.zipCodeSelect', '', '');
      return;
    }
    const ZipCodes = selectedZipCodes?.map(item => item.uuid);
    const Directors = selectedLocalLeaders?.map(item => item.uuid);
    if (stateData) {
      updateState({
        uuid: stateData?.results?.uuid,
        stateDetailed: {
          name: data.name,
          st: data.code,
          country: data.country,
          zipcodes: ZipCodes,
          directors: Directors,
          ordering: 1
        }
      })
        .then((res: any) => {
          responseHandler(res);
        })
        .catch((_error: any) => {
          triggerPopupData(true, false, 'state.stateCreationFailed', '', '');
        });
    } else {
      createState({
        stateDetailed: {
          name: data.name,
          st: data.code,
          country: data.country,
          zipcodes: ZipCodes,
          directors: Directors,
          ordering: 1
        }
      })
        .then((res: any) => {
          responseHandler(res);
        })
        .catch(() => {
          triggerPopupData(true, false, 'state.createFailedState', '', '');
        });
    }
  };

  const lastUserElementRef = useCallback(
    (node: any) => {
      if (cardRef.current) cardRef.current.disconnect();
      cardRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prevPage => prevPage + 5);
        }
      });
      if (node) cardRef.current.observe(node);
    },
    [hasMore]
  );

  const lastZipCodeElementRef = useCallback(
    (node: any) => {
      if (zipCardRef.current) zipCardRef.current.disconnect();
      zipCardRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasZipMore) {
          setZipPage(prevPage => prevPage + 6);
        }
      });
      if (node) zipCardRef.current.observe(node);
    },
    [hasZipMore]
  );

  useEffect(() => {
    setUserDataFilterOption({ ...userDataFilterOption, limit: page });
  }, [page]);

  useEffect(() => {
    setZipFilterOptions({ ...zipFilterOptions, limit: zipPage });
  }, [zipPage]);

  useEffect(() => {
    if (
      (watch('name') !== '' ||
        watch('country') !== '' ||
        watch('code') !== '') &&
      stateId?.id === null
    ) {
      setIsChange(true);
    } else if (
      (watch('name') !== stateData?.results?.name ||
        watch('code') !== stateData?.results?.st) &&
      stateId?.id !== null
    ) {
      setIsChange(true);
    } else {
      setIsChange(false);
    }
  }, [watch('name'), watch('country'), watch('code')]);

  return (
    <BaseAppLayout>
      {showAlert && (
        <Alert
          onClose={() => {
            setShowAlert(false);
            dispatch(setAlertDetails(''));
          }}
          autoHide
          {...alertData}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="lg:flex block items-end">
          <div className="w-full mt-4  block md:flex gap-8">
            <div className="block relative w-full bgW-sec">
              <h1 className="text-[var(--na-primary-color)] text-sm">
                {t('state.title')}
              </h1>
              <div className="w-full block md:flex mt-3 gap-2 mb-5 md:mb-0">
                <div className="w-full lg:w-5/12">
                  <label
                    htmlFor="country"
                    className="text-[var(--na-lite-gray4)] text-sm font-medium">
                    {t('state.selectCountry')}
                  </label>
                  <Select
                    id="timezone"
                    name="country"
                    disabled={stateData}
                    options={[
                      {
                        label: t('state.selectCountry'),
                        value: ''
                      },
                      ...countryOptions
                    ]}
                    {...register('country', {
                      required: {
                        value: true,
                        message: t('required')
                      }
                    })}
                    {...{
                      error: errors?.['country']?.message
                    }}
                    className="select select-bordered border-primary w-full text-sm font-medium p-3 mt-2 mb-4 text-[var(--na-lite-gray4)] focus:outline-none"
                  />
                </div>
                <div className=" ">
                  <label
                    htmlFor="state"
                    className="text-[var(--na-lite-gray4)] text-sm font-medium">
                    {t('state.stateName')}
                  </label>
                  <InputField
                    type="text"
                    data-testid="country-name"
                    className="border-primary rounded-md  py-1.5 p-1 text-[var(--na-lite-gray4)] mt-2 w-full mb-2"
                    placeholder="Enter State Name"
                    id="country-name"
                    name="state"
                    {...register('name', {
                      required: {
                        value: true,
                        message: t('required')
                      }
                    })}
                    {...{
                      error: errors?.['name']?.message
                    }}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="country"
                    className="text-[var(--na-lite-gray4)] text-sm font-medium">
                    {t('state.stateCode')}
                  </label>
                  <InputField
                    type="text"
                    name="code"
                    data-testid="country-name"
                    className="border-primary rounded-md  py-1.5 p-1 text-[var(--na-lite-gray4)] mt-2 w-full mb-2"
                    placeholder="Enter State Code"
                    id="country-name"
                    {...register('code', {
                      required: {
                        value: true,
                        message: t('required')
                      }
                    })}
                    {...{
                      error: errors?.['name']?.message
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col lg:flex-row gap-5 mt-4">
                  <div
                    className="w-full lg:w-5/12 bg-white border border-primary-color-hover rounded-lg p-1"
                    data-testid="area-card">
                    <AreaCard
                      onZipCodeSelection={handleZipCodeSelection}
                      onLocalLeaderSelection={handleLocalLeaderSelection}
                      selectedLocalLeaders={selectedLocalLeaders}
                      selectedZipCodes={selectedZipCodes}
                      displayItems={usersData?.results}
                      zipCodeList={zipCodeList}
                      zipCodeSearchQuery={zipCodeSearchQuery}
                      setZipCodeSearchQuery={setZipCodeSearchQuery}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      lastUserElementRef={lastUserElementRef}
                      lastZipCodeElementRef={lastZipCodeElementRef}
                      isZipCodesLoading={zipCodeIsFetching}
                      isUsersLoading={userFetch}
                      onZipCodeRemoval={handleRemoveZipCode}
                      leader={'volunteer.regional'}
                    />
                  </div>
                  <div className="w-full lg:w-7/12 flex flex-col gap-4">
                    <div
                      className="bg-white rounded-lg p1 card-shadow"
                      data-testid="zip-card">
                      <AssignedZipCodeCard
                        selectedZipCodes={selectedZipCodes}
                        onRemoveZipCode={handleRemoveZipCode}
                        isLocalAreaCard={true}
                        timezones={stateData?.results?.zipcodes}
                        isEditable={true}
                      />
                    </div>
                    <div
                      className="bg-white rounded-lg p-1 card-shadow"
                      data-testid="leader-card">
                      <LocalLeaderCard
                        onRemoveLocalLeader={handleRemoveLocalLeader}
                        displayItems={selectedLocalLeaders}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {stateId?.id && !isCancel ? (
          <>
            <p className="mt-5 text-[--na-secondary-color] text-sm">
              {t('localArea.updateConfirmMsg')}
            </p>
            <div className="mt-5 flex flex-col md:flex-row items-center gap-4 lg:gap-8">
              <Button
                btnStyle="primary-outline"
                testid="change-cancel"
                className="rounded-full px-5 py-1.5 !font-medium md:w-fit w-2/3 text-sm"
                onClick={() => {
                  if (isChange) {
                    setIsCancel(true);
                  } else {
                    dispatch(setStateId({ id: '' }));
                    navigate(routePaths.StateList);
                  }
                }}>
                {t('cancel')}
              </Button>

              <Button
                type="submit"
                testid="local-update"
                btnStyle="primary"
                className="rounded-full px-5 py-1.5 !font-medium md:w-fit w-2/3 text-sm">
                {t('state.update')}
              </Button>
            </div>
          </>
        ) : !isCancel ? (
          <div className="mt-6 flex flex-col md:flex-row items-center gap-4 lg:gap-8">
            <Button
              btnStyle="primary-outline"
              testid="change-cancel"
              className="rounded-full px-5 py-1.5 !font-medium md:w-24 w-2/3 text-sm"
              onClick={() => {
                if (isChange) {
                  setIsCancel(true);
                } else {
                  dispatch(setStateId({ id: '' }));
                  navigate(routePaths.StateList);
                }
              }}>
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              btnStyle="primary"
              className="rounded-full px-5 py-1.5 !font-medium md:w-fit w-2/3 text-sm">
              {t('state.createState')}
            </Button>
          </div>
        ) : (
          <></>
        )}
        {isCancel && (
          <>
            {' '}
            <p className="mt-5 text-[--na-secondary-color] text-sm">
              {t('localArea.exitConfirmMsg')}
            </p>
            <div className="mt-5 flex flex-col md:flex-row items-center gap-4 lg:gap-8">
              <Button
                btnStyle="primary-outline"
                testid="continue-button"
                className="rounded-full px-5 py-1.5 !font-medium md:w-fit w-2/3 text-sm"
                onClick={() => setIsCancel(false)}>
                {t('localArea.continue')}
              </Button>
              <Button
                btnStyle="primary"
                testid="exit-button"
                className="rounded-full px-5 py-1.5 !font-medium md:w-fit w-2/3 text-sm"
                onClick={() => {
                  dispatch(setStateId({ id: '' }));
                  navigate(routePaths.StateList);
                  setIsCancel(false);
                }}>
                {t('localArea.yesExit')}
              </Button>
            </div>
          </>
        )}
      </form>
    </BaseAppLayout>
  );
};
export default CreateState;

import { FC } from 'react';
import { SVGAssets } from '@business/assets/vectors';
import { AppLoader } from '@business/workflows/ComponentUI/AppLoader';

interface AppPublicLayoutProps {
  // id?: string;
  mainClass?: string;
  // baseAppClass?: string;
}

const AppPublicLayout: FC<React.PropsWithChildren<AppPublicLayoutProps>> = ({
  // id = '',
  mainClass = '',
  // baseAppClass = '',
  children
}) => {
  return (
    <>
      <main
        className="lasagna-theme relative w-full app-public-layout"
        data-testid="app-error-layout">
        <div data-testid="app-public-layout">
          <div className={mainClass}>
            <div className="navbar bg-white shadow-normal w-full py-2 lg:py-4 px-5 md:px-12">
              <div className="flex-1 cursor-pointer">
                <SVGAssets.Logo className="w-12 h-12" />
              </div>
            </div>
            <section className="th-container content-section w-full">
              {children}
            </section>
            <div data-testid="app-loader">
              <AppLoader />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default AppPublicLayout;

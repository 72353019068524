import { createSlice } from '@reduxjs/toolkit';
import { SessionUploadProgressType } from '@business/entities/upload-progress';
import type { RootState } from './store';

const initialState: SessionUploadProgressType = {
  uploadProgress: 0
};

export const uploadProgressStateSlice = createSlice({
  name: 'uploadProgressStateSlice',
  initialState,
  reducers: {
    setUploadProgressDetails: (state, action) => {
      state.uploadProgress = action.payload;
    },
    resetUploadProgressDetails: () => {
      return initialState;
    }
  }
});

export const { setUploadProgressDetails, resetUploadProgressDetails } =
  uploadProgressStateSlice.actions;

export const getUploadProgressState = (state: RootState) =>
  state.uploadProgressStateSlice;

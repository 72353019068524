import { enhancedApi as userApi } from '@ports/internal-services/user/userSlice';
import { enhancedApi as countryApi } from '@ports/internal-services/country/countrySlice';
import { enhancedApi as downloadCenterApi } from '@ports/internal-services/downloadCenter/downloadCenterSlice';
import { enhancedApi as faqApi } from '@ports/internal-services/faq/faqSlice';
import { enhancedApi as fileExportApi } from '@ports/internal-services/fileExport/fileExportSlice';
import { enhancedApi as localAreaApi } from '@ports/internal-services/localarea/localareaSlice';
import { enhancedApi as manualMatchingApi } from '@ports/internal-services/manualMatching/manualMatchingSlice';
import { enhancedApi as mealRequestApi } from '@ports/internal-services/mealRequest/mealRequestSlice';
import { enhancedApi as resourceApi } from '@ports/internal-services/resource/resourceApiSlice';
import { enhancedApi as scheduleApi } from '@ports/internal-services/schedule/scheduleSlice';
import { enhancedApi as stateApi } from '@ports/internal-services/state/stateSlice';
import { enhancedApi as zipCodesApi } from '@ports/internal-services/zipCodes/zipCodeSlice';
export const apiSlices = [
  userApi,
  downloadCenterApi,
  countryApi,
  faqApi,
  fileExportApi,
  localAreaApi,
  manualMatchingApi,
  mealRequestApi,
  resourceApi,
  scheduleApi,
  stateApi,
  zipCodesApi
];

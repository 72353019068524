import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { getCognitoToken } from '@utils/helpers/lib/token';
import { dotenv } from '@utils/constants/env';
import { store } from './store';
import { setToken } from './sessionStateSlice';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: dotenv.API_BASE_URL,
  prepareHeaders: async headers => {
    const storeToken = store?.getState()?.sessionStateSlice?.token;
    const sessionToken = sessionStorage.getItem('token');
    const token = storeToken ? storeToken : sessionToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});
const baseQueryWithOutToken = fetchBaseQuery({
  baseUrl: dotenv.API_BASE_URL,
  prepareHeaders: async headers => {
    return headers;
  }
});

const baseQueryNotification = fetchBaseQuery({
  baseUrl: dotenv.NOTIFICATION_BASE_URL,
  prepareHeaders: headers => {
    const token: string = store?.getState()?.sessionStateSlice?.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions: any) => {
  const storeToken = store?.getState()?.sessionStateSlice?.token;
  const sessionToken = sessionStorage.getItem('token');
  const token = storeToken ? storeToken : sessionToken;

  const unauthorizedRoute = [
    '/user/notifiedfrom/',
    '/region/country/',
    '/region/state/lite/'
  ];
  const unAuthorizedMutation = ['/user/callback/', '/matching/meal_request/'];
  let result: any;
  try {
    if (typeof args !== 'string') {
      const isUnauthorized =
        api.type === 'query'
          ? unauthorizedRoute.includes(args?.url)
          : unAuthorizedMutation.includes(args?.url);
      if (!isUnauthorized && token) {
        await mutex.waitForUnlock();
        if (extraOptions?.notification) {
          result = await baseQueryNotification(args, api, extraOptions);
        } else {
          result = await baseQuery(args, api, extraOptions);
        }

        if (result.error?.status === 401) {
          if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
              const cognitoToken = await getCognitoToken();
              const token = cognitoToken ? cognitoToken?.idToken : '';
              if (token) {
                if (storeToken) {
                  store.dispatch(
                    setToken({
                      token
                    })
                  );
                } else {
                  sessionStorage.setItem('token', token);
                }
                if (extraOptions?.notification) {
                  result = await baseQueryNotification(args, api, extraOptions);
                } else {
                  result = await baseQuery(args, api, extraOptions);
                }
              }
            } catch (error) {
              console.log('refreshError', error);
            } finally {
              // release must be called once the mutex should be released again.
              release();
            }
          } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            if (extraOptions?.notification) {
              result = await baseQueryNotification(args, api, extraOptions);
            } else {
              result = await baseQuery(args, api, extraOptions);
            }
          }
        }
        if (typeof result.error != 'undefined') {
          if (result.error && result.error.status === 400) {
            api.dispatch({ type: 'INCREMENT' });
          } else if (
            result.error &&
            result.error.status === 500 &&
            (result.error?.data as Error).message === 'jwt expired'
          ) {
            /* Call logoutUser */
          }
        }
      } else if (isUnauthorized) {
        result = await baseQueryWithOutToken(args, api, extraOptions);
      } else {
        return null;
      }
    }
  } catch (error) {
    console.log('APICallError', error);
    result = {
      error: {
        status: 401,
        data: {
          results: {
            detail:
              'Unable to contact the server now. Please try after sometime.'
          },
          ok: false,
          message: {
            code: 'ServerDown',
            args: null,
            text: []
          }
        }
      }
    };
  }
  return result;
};

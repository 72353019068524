import { Images } from '@business/assets/images';
import { useTranslation } from 'react-i18next';

export const NoDataComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col justify-center items-center p-4">
      <img
        src={Images.NoData}
        alt=""
        className="h-[200px] object-cover rounded-t-lg"
      />
      <p className="pt-2">{t('noDataFound')}</p>
    </div>
  );
};

import React, { FC } from 'react';
import { PaginationProps } from '@business/interfaces/pagination';
import { Button } from '../Button';

export const Pagination: FC<PaginationProps> = ({
  totalCount,
  currentPage,
  pageSize = 10,
  onPageChange,
  siblingCount = 1,
  dots = '...'
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const getPageNumbers = () => {
    const pages = [];

    // Add first page
    pages.push(1);

    // Add pages around current page
    for (
      let i = currentPage - siblingCount;
      i <= currentPage + siblingCount;
      i++
    ) {
      if (i > 1 && i < totalPages) {
        pages.push(i);
      }
    }

    if (totalPages > 1) {
      // Add last page
      pages.push(totalPages);
    }
    // Add dots
    const pageNumbers = [];
    let prevPage = 0;
    for (const page of pages) {
      if (page - prevPage === 2) {
        pageNumbers.push(prevPage + 1);
      } else if (page - prevPage > 2) {
        pageNumbers.push(dots);
      }
      pageNumbers.push(page);
      prevPage = page;
    }

    return pageNumbers;
  };

  const handleClick = (page: any) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex justify-end mt-3">
      <div
        className="isolate inline-flex rounded-md shadow-none gap-2"
        aria-label="Pagination">
        {pageNumbers.length > 1 && (
          <>
            <Button
              onClick={() => {
                if (currentPage > 1) {
                  handleClick(currentPage - 1);
                }
              }}
              isDisabled={currentPage === 1}
              btnStyle="link"
              className="bg-gray-300 relative inline-flex items-center rounded-md px-2 py-2 text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Previous</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>

            {pageNumbers.map((page, index) => {
              if (page === dots) {
                return <span key={index + dots}>{dots}</span>;
              }
              return (
                <Button
                  key={page}
                  btnStyle="link"
                  aria-current="page"
                  className={`relative inline-flex items-center px-4 py-2 text-sm rounded-md font-semibold text-white ${
                    currentPage === page
                      ? 'bg-[var(--na-primary-color)]  z-10 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      : 'bg-gray-300 ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:z-20 focus:outline-offset-0'
                  }`}
                  onClick={() => handleClick(page)}>
                  {page}
                </Button>
              );
            })}

            <Button
              onClick={() => {
                if (currentPage < totalPages) {
                  handleClick(currentPage + 1);
                }
              }}
              isDisabled={currentPage === totalPages}
              btnStyle="link"
              data-testid="next-button"
              className="relative inline-flex items-center rounded-r-md px-2 py-2 rounded-md bg-gray-300 text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-500 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Next</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
